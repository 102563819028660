// import i18n from "i18next";
// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// i18n
//   .use(initReactI18next)
//   .use(Backend)
//   .use(LanguageDetector)
//   .init({
//     react: { useSuspense: false },
//     debug: false,
//     fallbackLng: "en",
//     backend: {
//       loadPath: (lng, ns) => {
//         const isDev = process.env.NODE_ENV === 'development';
//         return isDev 
//           ? `/locales/${lng}/${ns}.json`
//           : `https://vacbid.com/locales/${lng}/${ns}.json`;
//       }
//     },
//     detection: {
//       order: ['path', 'navigator'],
//       lookupFromPathIndex: 0,
//     },
//     interpolation: {
//       escapeValue: false // XSS koruması için (React zaten güvenli hale getiriyor)
//     },
//     saveMissing: false // Eksik çevirileri kaydetmek istemiyorsanız kapatın
//   });

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const isBrowser = typeof window !== 'undefined';
i18n
.use(initReactI18next)
.use(Backend)
.use(LanguageDetector)
.init({
  // react: { useSuspense: false },
    debug:false,
    fallbackLng:"en",
    backend: {
      loadPath: isBrowser
        ? '/locales/{{lng}}/{{ns}}.json' // İstemci tarafında bağıl URL kullanın
        : 'https://vacbid.com/locales/{{lng}}/{{ns}}.json' // Sunucu tarafında tam URL kullanın
    },
    detection: {
        order: ['path','cookie', 'localStorage', 'navigator'],
        caches: ['cookie'],
        lookupFromPathIndex: 0,
      },
      interpolation: {
        escapeValue: false, // XSS saldırılarına karşı değerleri kaçırma
      }
})

// export const languages = ["en","tr","en-US","tr-TR","az-AZ"]

// export const removeLngPrefix =(pathname)=>{

//    for (let lang of languages) {
//     if(pathname.startsWith(`/${lang}/` || pathname===`/${lang}` )){
//         return pathname.replace(`/${lang}`,``)
//     }
//    }
//     return pathname;
// }

export default i18n;







